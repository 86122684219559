var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("ul", [
      _c("li", [
        _vm._v(
          "Here are some examples of natural non-synthesized human voices."
        ),
      ]),
      _c("li", [
        _vm._v(
          "You cannot move onto the first question until you listen to all sample voices at least once for each."
        ),
      ]),
      _c(
        "li",
        [
          _c("font", { attrs: { color: "red" } }, [
            _vm._v(
              "Note that you will not be able to listen the natural human voices after this."
            ),
          ]),
        ],
        1
      ),
      _c("br"),
      _c("p", [_vm._v("Natural human voice samples :")]),
      _c("table", [
        _c("tr", [
          _c("td", [
            _c(
              "ul",
              [
                _c("li", [_vm._v(" sample 1 : ")]),
                _c(
                  "v-btn",
                  {
                    staticClass: "play-btn",
                    attrs: {
                      elevation: "2",
                      disabled:
                        !_vm.audio.A.canPlay ||
                        _vm.audio.B.playing ||
                        _vm.audio.C.playing,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.togglePlaying("A")
                      },
                    },
                  },
                  [
                    !_vm.audio.A.canPlay
                      ? _c("v-icon", [_vm._v("mdi-cached")])
                      : !_vm.audio.A.playing
                      ? _c("v-icon", [_vm._v("mdi-play")])
                      : _c("v-icon", [_vm._v("mdi-stop")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("td", [
            _c(
              "ul",
              [
                _c("li", [_vm._v(" sample 2 : ")]),
                _c(
                  "v-btn",
                  {
                    staticClass: "play-btn",
                    attrs: {
                      elevation: "2",
                      disabled:
                        !_vm.audio.B.canPlay ||
                        _vm.audio.A.playing ||
                        _vm.audio.C.playing,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.togglePlaying("B")
                      },
                    },
                  },
                  [
                    !_vm.audio.B.canPlay
                      ? _c("v-icon", [_vm._v("mdi-cached")])
                      : !_vm.audio.B.playing
                      ? _c("v-icon", [_vm._v("mdi-play")])
                      : _c("v-icon", [_vm._v("mdi-stop")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("td", [
            _c(
              "ul",
              [
                _c("li", [_vm._v(" sample 3 : ")]),
                _c(
                  "v-btn",
                  {
                    staticClass: "play-btn",
                    attrs: {
                      elevation: "2",
                      disabled:
                        !_vm.audio.C.canPlay ||
                        _vm.audio.A.playing ||
                        _vm.audio.B.playing,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.togglePlaying("C")
                      },
                    },
                  },
                  [
                    !_vm.audio.C.canPlay
                      ? _c("v-icon", [_vm._v("mdi-cached")])
                      : !_vm.audio.C.playing
                      ? _c("v-icon", [_vm._v("mdi-play")])
                      : _c("v-icon", [_vm._v("mdi-stop")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("p", { staticClass: "mb5" }),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: {
            disabled:
              !_vm.allPlayedOnce ||
              _vm.audio.A.playing ||
              _vm.audio.B.playing ||
              _vm.audio.C.playing,
          },
          on: {
            click: function ($event) {
              _vm.canSubmit ? _vm.submit() : false
            },
          },
        },
        [_vm._v("Go To the first question")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Human voices")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }