var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c("p", { staticClass: "mb5" }),
    _c("p"),
    _vm._m(2),
    _c("ul", [
      _c("li", [
        _vm._v(
          "You cannot move onto the next question until you listen to both voice A and B at least once for each."
        ),
      ]),
      _c("li", [
        _vm._v("While one voice is playing, the other voice cannot be played."),
      ]),
      _c("li", [
        _vm._v(
          'You cannot choose "which is the better sound quality" while the voice is playing.'
        ),
      ]),
      _c(
        "li",
        [
          _vm._v("In the Easy Tasks, "),
          _c("font", { attrs: { color: "red" } }, [
            _vm._v(
              "if you are judged to have distracted answers in the tasks, the entire HIT may be rejected"
            ),
          ]),
          _vm._v("."),
        ],
        1
      ),
      _c(
        "li",
        [
          _vm._v("In the Hard Tasks, "),
          _c("font", { attrs: { color: "red" } }, [
            _vm._v("if you scored good results, you will be given a bonus"),
          ]),
          _vm._v("."),
        ],
        1
      ),
      _c(
        "li",
        [
          _vm._v("In case "),
          _c("font", { attrs: { color: "red" } }, [
            _vm._v("any technical issue"),
          ]),
          _vm._v(" occurs (which prevents you to move forward), "),
          _c("b", [_vm._v("provide details and click the button to skip")]),
          _vm._v("."),
        ],
        1
      ),
    ]),
    _c("p", { staticClass: "mb5" }),
    _c("p"),
    _vm._m(3),
    _c("p", [
      _vm._v(
        "If you have questions, contact us at mturk04@pcl.cs.waseda.ac.jp."
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Instructions :")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Listen to voice A and B, and select the voice that you think has less distortion."
        ),
      ]),
      _c("li", [
        _vm._v(
          'Possible choices are "Definitely A", "Maybe A", "Maybe B", and "Definitely B".'
        ),
      ]),
      _c("li", [
        _vm._v("The first half is the "),
        _c("b", [_vm._v("Easy Tasks")]),
        _vm._v(", and the second half is the "),
        _c("b", [_vm._v("Hard Tasks")]),
        _vm._v("."),
      ]),
      _c("li", [
        _vm._v("The Easy Tasks have a total of "),
        _c("b", [_vm._v("12 questions")]),
        _vm._v(", and the Hard Tasks have a total of "),
        _c("b", [_vm._v("18 questions")]),
        _vm._v(". (Estimated completion time : 15 [min])"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Note :")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("The audio data used on this site is from the following site : "),
      _c(
        "a",
        { attrs: { href: "https://datashare.ed.ac.uk/handle/10283/3061/" } },
        [_vm._v("The Voice Conversion Challenge 2018")]
      ),
      _vm._v(" by "),
      _c("a", { attrs: { href: "http://vc-challenge.org/" } }, [
        _vm._v("vc-challenge.org"),
      ]),
      _vm._v(" is licensed under "),
      _c(
        "a",
        {
          attrs: {
            href: "https://datashare.ed.ac.uk/bitstream/handle/10283/3061/license_text?sequence=11&isAllowed=y",
          },
        },
        [_vm._v("Creative Commons")]
      ),
      _vm._v(" and "),
      _c(
        "a",
        {
          attrs: {
            href: "https://datashare.ed.ac.uk/bitstream/handle/10283/3061/license.txt?sequence=12&isAllowed=y",
          },
        },
        [_vm._v("Depositor Agreement")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }