var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("ul", [
      _c("li", [_vm._v("From here, the Hard Tasks start.")]),
      _c("li", [_vm._v("There are 18 questions in total.")]),
      _c("li", [
        _vm._v(
          'You can start it by clicking on the "Go To the Hard Task" button below.'
        ),
      ]),
      _c(
        "li",
        [
          _c("font", { attrs: { color: "red" } }, [
            _vm._v(
              "Note that if you scored good results, you will be given a bonus"
            ),
          ]),
          _vm._v("."),
        ],
        1
      ),
    ]),
    _c("p", { staticClass: "mb5" }),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              _vm.canSubmit ? _vm.submit() : false
            },
          },
        },
        [_vm._v("Go To the Hard Task")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Hard Task")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }