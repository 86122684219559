var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q1.")]),
      _vm._v(" Tell your gender."),
      _c("br"),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "v-radio-group",
            {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: { row: "" },
              model: {
                value: _vm.gender,
                callback: function ($$v) {
                  _vm.gender = $$v
                },
                expression: "gender",
              },
            },
            [
              _c(
                "div",
                { staticClass: "check-item" },
                [_c("v-radio", { attrs: { label: "Male", value: "Male" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: { label: "Female", value: "Female" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [_c("v-radio", { attrs: { label: "Other", value: "Other" } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q2.")]),
      _vm._v(" Tell your age. (Please input a single-byte number.)"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true },
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.agetext,
            expression: "agetext",
          },
        ],
        attrs: { type: "number", min: "0", max: "120" },
        domProps: { value: _vm.agetext },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.agetext = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q3.")]),
      _vm._v(" Tell your English level."),
      _c("br"),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "v-radio-group",
            {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: { row: "" },
              model: {
                value: _vm.Englevel,
                callback: function ($$v) {
                  _vm.Englevel = $$v
                },
                expression: "Englevel",
              },
            },
            [
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: { label: "Native", value: "Native" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: { label: "Business", value: "Business" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Daily Conversation",
                      value: "Daily Conversation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Basic (Minimum conversation / learning)",
                      value: "Basic (Minimum conversation / learning)",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q4.")]),
      _vm._v(" Tell your native language."),
      _c("br"),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c("v-select", {
                directives: [
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true },
                  },
                ],
                attrs: { solo: "", items: _vm.languages, label: "Select one" },
                model: {
                  value: _vm.selectlanguage,
                  callback: function ($$v) {
                    _vm.selectlanguage = $$v
                  },
                  expression: "selectlanguage",
                },
              }),
            ],
            1
          ),
          _vm.selectlanguage == "Other"
            ? _c(
                "div",
                [
                  _c("v-text-field", {
                    directives: [{ name: "nano", rawName: "v-nano" }],
                    staticStyle: { width: "300px" },
                    attrs: { label: "Specify your language...", clearable: "" },
                    model: {
                      value: _vm.languagetext,
                      callback: function ($$v) {
                        _vm.languagetext = $$v
                      },
                      expression: "languagetext",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q5.")]),
      _vm._v(
        "Tell the situations in which you use synthetic voices. (Multiple answers are acceptable) "
      ),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "check-item2" },
          [
            _c("v-checkbox", {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: {
                value: "Voice assistants in smartphone",
                label: "Voice assistants in smartphone",
              },
              model: {
                value: _vm.situations,
                callback: function ($$v) {
                  _vm.situations = $$v
                },
                expression: "situations",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "check-item" },
          [
            _c("v-checkbox", {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: {
                value: "Voice guidance in commercial facilities",
                label: "Voice guidance in commercial facilities",
              },
              model: {
                value: _vm.situations,
                callback: function ($$v) {
                  _vm.situations = $$v
                },
                expression: "situations",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "check-item2" },
          [
            _c("v-checkbox", {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: {
                value: "Evaluated synthesized voices before",
                label: "Evaluated synthesized voices before",
              },
              model: {
                value: _vm.situations,
                callback: function ($$v) {
                  _vm.situations = $$v
                },
                expression: "situations",
              },
            }),
          ],
          1
        ),
        _vm.situations.includes("Evaluated synthesized voices before")
          ? _c(
              "div",
              [
                _c("v-text-field", {
                  directives: [{ name: "nano", rawName: "v-nano" }],
                  staticStyle: { width: "350px" },
                  attrs: {
                    label: "Specify the number of past evaluations...",
                    clearable: "",
                  },
                  model: {
                    value: _vm.evaluatenum,
                    callback: function ($$v) {
                      _vm.evaluatenum = $$v
                    },
                    expression: "evaluatenum",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "check-item2" },
          [
            _c("v-checkbox", {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: {
                value: "Speech Researchers",
                label: "Speech Researchers",
              },
              model: {
                value: _vm.situations,
                callback: function ($$v) {
                  _vm.situations = $$v
                },
                expression: "situations",
              },
            }),
          ],
          1
        ),
        _vm.situations.includes("Speech Researchers")
          ? _c(
              "div",
              [
                _c("v-text-field", {
                  directives: [{ name: "nano", rawName: "v-nano" }],
                  staticStyle: { width: "300px" },
                  attrs: { label: "Specify your field...", clearable: "" },
                  model: {
                    value: _vm.field,
                    callback: function ($$v) {
                      _vm.field = $$v
                    },
                    expression: "field",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "check-item2" },
          [
            _c("v-checkbox", {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: { value: "Others", label: "Others" },
              model: {
                value: _vm.situations,
                callback: function ($$v) {
                  _vm.situations = $$v
                },
                expression: "situations",
              },
            }),
          ],
          1
        ),
        _vm.situations.includes("Others")
          ? _c(
              "div",
              [
                _c("v-text-field", {
                  directives: [{ name: "nano", rawName: "v-nano" }],
                  staticStyle: { width: "300px" },
                  attrs: { label: "Specify situation...", clearable: "" },
                  model: {
                    value: _vm.situationtext,
                    callback: function ($$v) {
                      _vm.situationtext = $$v
                    },
                    expression: "situationtext",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q6.")]),
      _vm._v(
        " In this task, you will listen some voices. Please tell us about your listening environment. "
      ),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "v-radio-group",
            {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: { row: "" },
              model: {
                value: _vm.environment,
                callback: function ($$v) {
                  _vm.environment = $$v
                },
                expression: "environment",
              },
            },
            [
              _c(
                "div",
                { staticClass: "check-item2" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Earbud headphones",
                      value: "Earbud headphones",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item2" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "On/over-ear headphones",
                      value: "On/over-ear headphones",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item2" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Loud speaker(s)",
                      value: "Loud speaker(s)",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item2" },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "PC built-in speaker(s)",
                      value: "PC built-in speaker(s)",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [_c("v-radio", { attrs: { label: "Other", value: "Other" } })],
                1
              ),
              _vm.environment == "Other"
                ? _c(
                    "div",
                    [
                      _c("v-text-field", {
                        directives: [{ name: "nano", rawName: "v-nano" }],
                        staticStyle: { width: "300px" },
                        attrs: {
                          label: "Specify environment...",
                          clearable: "",
                        },
                        model: {
                          value: _vm.environmenttext,
                          callback: function ($$v) {
                            _vm.environmenttext = $$v
                          },
                          expression: "environmenttext",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit },
          on: {
            click: function ($event) {
              _vm.canSubmit ? _vm.submit() : false
            },
          },
        },
        [_vm._v("NEXT")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Pre-survey.")]),
      _vm._v(" Please answer the following questions first. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }